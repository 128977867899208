import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage, { IFluidObject } from 'gatsby-background-image'

import { GBackgroundImage, Box } from '@components/UI'
import Layout from '@components/Layout'

import { Query } from '@generated/graphql'

const HomeImage = () => {
  const images: Query = useStaticQuery(graphql`
    {
      vertical: file(
        sourceInstanceName: { eq: "page_design" }
        name: { eq: "home_page_kitty_vertical" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      landscape: file(
        sourceInstanceName: { eq: "page_design" }
        name: { eq: "home_page_kitty_landscape" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // @ts-ignore graphql type doesn't match BackgroundImage type
  const fluidImageLandscape: IFluidObject =
    images.landscape.childImageSharp.fluid
  const fluidImageVertical: IFluidObject = images.vertical.childImageSharp.fluid

  return (
    <Layout fullScreen transparent title="Home">
      <GBackgroundImage
        display={['block', 'none', 'none', 'none']}
        Tag="section"
        style={{ height: '100vh', width: '100vw' }}
        fluid={fluidImageVertical}
      />
      <GBackgroundImage
        display={['none', 'block', 'block', 'block']}
        Tag="section"
        style={{ height: '100vh' }}
        fluid={fluidImageLandscape}
      />
    </Layout>
  )
}

export default HomeImage
